import React, { createContext, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'lodash';
import { updateActivitiesDictionary } from '../../redux/thunks/activity-slice.thunk';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';
import { selectCanFetchData } from '../../redux/slices/app/user.slice';
import { selectActivityBroadcastData, selectActivityEntities } from '../../redux/slices/app/activity.slice';
import { selectCalendarEntities } from '../../redux/slices/app/calendar.slice';
import { selectActiveTab, selectTabPreferences } from '../../redux/slices/app/tab.slice';
import { shouldUpdateSlices } from '../../redux/utils/ActivityCalendarSliceUtils';
import { TAB_TYPE } from '../../constants/Tabs';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import i18n from '../../i18n';
import { updateActivityResourceVirtualTasks } from '../../redux/thunks/activity-calendar-slice.thunk';
import handleActivityBroadcastMessage from '../../redux/thunks/broadcast-slice.thunk';
import { updateTaskStripes, updateGanttTask, formatNewGanttTask} from '../../helpers/planningUtils';
import { selectLinkBroadcastData } from '../../redux/slices/app/link.slice';
import { generateLinkNodes } from '../../helpers/planning';
import { ObjectPoolHandler } from '../../utils/ObjectPoolHandler';

/**
 * Context for managing planning-related state and operations
 * @type {React.Context}
 */
const PlanningContext = createContext({});

/**
 * Provider component for planning-related functionality
 * Manages:
 * - Activity broadcast updates and synchronization
 * - Gantt chart task updates
 * - WebSocket connections for real-time updates
 * - Gantt Link updates
 * @component
 */
const PlanningContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    const planningSelected = useSelector(selectPlanningSelected);
    const tabPreferences = useSelector(selectTabPreferences);
    const activitiesDictionary = useSelector(selectActivityEntities);
    const calendarsDictionary = useSelector(selectCalendarEntities);
    const canFetchData = useSelector(selectCanFetchData);
    const activeTab = useSelector(selectActiveTab);
    const updatedDataActivityBroadcast = useSelector(selectActivityBroadcastData);
    const linkBroadcastData = useSelector(selectLinkBroadcastData);
    /**
     * Simulates a broadcast message for activity updates
     * @param {string} id - The ID of the activity to update
     * @returns {Promise} Dispatch result of the broadcast message
     */
    const updateActivityByBroadcast = useCallback(
        (id) =>
            dispatch(
                handleActivityBroadcastMessage({
                    data: JSON.stringify({
                        context: 'ACTIVITY',
                        id,
                        rootId: planningSelected?.rootActivityId,
                        type: 'Activity',
                        origin: 'FRONT',
                    }),
                })
            ),
        [planningSelected]
    );

    /**
     * Object pool handler for managing broadcast updates queue
     * Ensures sequential processing of updates with controlled intervals
     */
    const broadcastPool = useRef(
        new ObjectPoolHandler({
            maxQueueSize: 100,
            processInterval: 100
        })
    );

    /**
     * Processes a single update in the broadcast queue
     * Handles task creation, updates, and deletion in Gantt chart
     * @param {Object} update - The update object to process
     * @param {Object} update.updatedData - New data for the activity
     * @param {boolean} update.isTaskExist - Whether the task exists in Gantt
     * @param {boolean} update.isNewTask - Whether this is a new task
     * @param {string} update.id - Activity ID
     */
    const processUpdate = useCallback(async (update) => {
        const { updatedData, isTaskExist, isNewTask, id } = update;
        const isGroupement = tabPreferences?.filtered_group?.groupingType === 'custom_fields';

        try {
            // Handle activity data update
            if (updatedData) {
                await dispatch(updateActivitiesDictionary(updatedData.id, clone(updatedData)));

                // Handle new task creation
                if (!isTaskExist && !isGroupement) {
                    const task = formatNewGanttTask(updatedData, tabPreferences);
                    window.ganttInstance.addTask(task);
                } 
                // Handle existing task update
                else if (isTaskExist && !isNewTask) {
                    const oldData = window.ganttInstance.getTaskByServerId(id);
                    const taskData = { ...updatedData };

                    if (isGroupement) {
                        taskData.type = oldData.type;
                        taskData.activityParentId = oldData.parent;
                    } else {
                        taskData.activityParentId = window.ganttInstance.getTaskByServerId(
                            updatedData.activityParentId
                        )?.id;
                    }
                    const task = updateGanttTask(taskData, tabPreferences);
                    window.ganttInstance.updateTaskByServerId(updatedData.id, task);
                }
                updateTaskStripes(dispatch);
            } 
            // Handle task deletion
            else if (isTaskExist) {
                window.ganttInstance.deleteTaskByServerId(id);
            }

            // Update resource histogram if needed
            const showHistogram =
                activeTab?.tabType === TAB_TYPE.GANTT
                    ? tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
                    : activeTab?.tabType === TAB_TYPE.RESOURCE;

            if (
                update &&
                !update.isNewTask &&
                shouldUpdateSlices(update.diffFields) &&
                showHistogram
            ) {
                await dispatch(
                    updateActivityResourceVirtualTasks({
                        taskId: update.id,
                        updatedData: update.updatedData,
                    })
                );
            }
        } catch (error) {
            console.error('Error processing update:', error);
        }
    }, [dispatch, tabPreferences, activeTab]);

    /**
     * Effect to handle activity broadcast updates
     * Uses ObjectPoolHandler for sequential processing of updates
     */
    useEffect(() => {
        if (!updatedDataActivityBroadcast || !planningSelected) return;
        
        // Add new update to pool and process it
        broadcastPool.current.addItem(updatedDataActivityBroadcast)
            .then(() => {
                broadcastPool.current.processQueue(processUpdate);
            })
            .catch(error => {
                console.error('Error handling broadcast update:', error);
            });
    }, [updatedDataActivityBroadcast, planningSelected, processUpdate]);

    /**
     * Effect to handle Gantt link updates
     * Manages creation, updates, and deletion of links between tasks
     */
    useEffect(() => {
        const handleLinkBroadcast = async () => {
            if (linkBroadcastData) {
                const { isLinkExists, updatedData, id } = linkBroadcastData;

                // new link from other users
                if (!isLinkExists && updatedData) {
                    const flattenActivities = window.ganttInstance.getTaskByTime();
                    const generatedNewLinks = generateLinkNodes([updatedData], flattenActivities);
                    console.log('generatedNewLinks', generatedNewLinks);
                    window.ganttDataProcessor = false;
                    generatedNewLinks.forEach(link => {
                        window.ganttInstance.addLink(link);
                    });
                    window.ganttDataProcessor = true;
                }
                // Update link in Gantt if updated data
                if (updatedData && isLinkExists) {
                    const flattenActivities = window.ganttInstance.getTaskByTime();
                    // Format the link data for Gantt
                    const generatedLinks = generateLinkNodes([updatedData], flattenActivities);
                    const formattedLinks = generatedLinks.map(link => {
                        const { id: _, ...linkWithoutId } = link;
                        return linkWithoutId;
                    });
                    formattedLinks.forEach(link => {
                        window.ganttInstance.updateLinkByServerId(link.serverId, link);
                    });
                }
                // Remove link from Gantt if no updated data
                if (!updatedData && isLinkExists) {
                    window.ganttInstance.deleteLinkByServerId(id);
                }
            }
        };
        handleLinkBroadcast();
    }, [linkBroadcastData]);

    /**
     * Effect to manage WebSocket connections
     * Handles connections to:
     * - General broadcast channel (folders/teams)
     * - Activity-specific broadcast channel
     */
    useEffect(() => {
        if (canFetchData) {
            // Connect to general broadcast channel for folders and teams updates
            dispatch({
                type: 'broadcast/connect-general',
                payload: {
                    url: `${window._env_.REACT_APP_FOLDER_API}/broadcast`,
                },
            });

            // Connect to activity-specific broadcast channel when planning is selected
            if (planningSelected?.id) {
                const connectionId = crypto.randomUUID();
                dispatch({
                    type: 'broadcast/connect-pusher',
                    payload: {
                        url: `${window._env_.REACT_APP_ACTIVITY_API}/broadcast-pusher/${planningSelected.rootActivityId}/${connectionId}`,
                    },
                });
            }
        }

        // Cleanup: disconnect from both channels when component unmounts
        return () => {
            dispatch({ type: 'broadcast/disconnect-general' });
            dispatch({ type: 'broadcast/disconnect-pusher' });
        };
    }, [planningSelected?.id]);

    return (
        <PlanningContext.Provider
            value={{
                updatedDataActivityBroadcast,
                updateActivityByBroadcast,
                activitiesDictionary,
                planningSelected,
                calendarsDictionary,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};
PlanningContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PlanningContext, PlanningContextProvider };
