import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useNeeds from '../../hooks/useNeeds';
import { selectCanFetchData } from '../../redux/slices/app/user.slice';

const NeedsContext = createContext(null);

const NeedsContextProvider = ({ children }) => {
    const canFetchData = useSelector(selectCanFetchData);
    const { 
        selectedNeed, 
        setSelectedNeed, 
        needList, 
        loading, 
        error, 
        refreshNeedList, 
        fetchNeeds 
    } = useNeeds(false);

    useEffect(() => {
        if (canFetchData) {
            fetchNeeds();
        }
    }, [canFetchData]);

    return (
        <NeedsContext.Provider
            value={{
                selectedNeed,
                setSelectedNeed,
                NeedList: needList,
                refreshNeedList,
                loading,
                error,
                fetchNeeds,
            }}
        >
            {children}
        </NeedsContext.Provider>
    );
};

NeedsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { NeedsContext, NeedsContextProvider };
